import React, { useState, useEffect } from 'react'
import data from '../data.json'
import Left from './Left'
import Right from './Right'
import Counter from './Counter'


function randomStart() {
    return data[Math.floor(Math.random() * data.length)]
}


const Game = ({ onPageChange, sethighScore, highScore, setPoint }) => {
    const [left, setLeft] = useState(randomStart())
    const [wrongOrRight, setWrongOrRight] = useState(null)
    const [right, setRight] = useState(() => {
        let newRight = randomStart()
        while (newRight === left) {
            newRight = randomStart()
        }
        return newRight
    })
    const [clicked, setClicked] = useState(null)
    useEffect(() => {
        if (clicked === 'more') {
            if (right.networth >= left.networth) {
                setWrongOrRight('succes')
                succes()
            }
            else {
                setWrongOrRight('wrong')
                wrong()
            }
            setClicked(null)
        }
        if (clicked === 'less') {
            if (right.networth > left.networth) {
                setWrongOrRight('wrong')
                wrong()
            }
            else {
                setWrongOrRight('succes')
                succes()
            }
            setClicked(null)
        }
        return () => {
            setClicked(null)
        }
    }, [clicked]);

    const [score, setScore] = useState(0)



    useEffect(() => {
        if (score > highScore) {
            sethighScore(score)
        }
    }, [score]);

    function succes() {

        setRightNetWorthRendered(true)
    }
    function wrong() {
        setRightNetWorthRendered(true)
    }
    const [rightNetWorthRendered, setRightNetWorthRendered] = useState(false)
    const [maxReached, setMaxReached] = useState(false);

    function handleMaxReached() {
        setMaxReached(true);
    }
    const [vscolor, setVscolor] = useState('white')
    useEffect(() => {
        if (maxReached) {

            if (wrongOrRight === 'wrong') {
                setVscolor('red')
                setTimeout(function () {
                    setScore(0)
                    handleEndGame()
                    setPoint(score)
                    setVscolor('white')
                    setRightNetWorthRendered(false)
                }, 1000);

            }
            if (wrongOrRight === 'succes') {
                setVscolor('green')
                setTimeout(function () {
                    setLeft(right)
                    setRight(() => {
                        let newRight = randomStart()
                        while (newRight === right) {
                            newRight = randomStart()
                        }
                        return newRight
                    })
                    setScore(score + 1)
                    setVscolor('white')
                    setRightNetWorthRendered(false)
                }, 1000);

            }
            setMaxReached(false)

        }
    }, [maxReached]);


    function handleEndGame() {
        onPageChange('end');
    }
    return (
        <div className='Game'>
            <Left obj={left} />
            <Right obj={right} setClicked={setClicked} score={score} />
                <div className='score'>Score: {score}</div>
                <div className='highScore'>High Score: {highScore}</div>

            {vscolor === "white" ?
                <h1 className='vs'>VS</h1> : vscolor === "green" ?
                    <h1 className='vsGreen'>VS</h1> :
                    <h1 className='vsRed'>VS</h1>}
            {rightNetWorthRendered && <Counter maxCount={right.networth} maxReached={handleMaxReached} />}
        </div>
    )
}

export default Game
