import React from 'react'
import {FaFacebookF} from 'react-icons/fa'

const ShareOnFacebook = ({}) => {
   const shareUrl =  `https://www.facebook.com/share.php?u=${encodeURI('https://whoisrichergame.com/')}`
  

    const handleClick = () => {
        window.open(shareUrl, '_blank');
      };
    return (

          <button onClick={handleClick} className='facebook' >
      <FaFacebookF /> Share
    </button>

  )
}

export default ShareOnFacebook
