import React from 'react'

const Left = ({obj}) => {
    return (
        <div className='Left'>
            <h1 className='Left__Name'>{obj.name}</h1>
            <h1 className='Left__Networth'>${obj.networth.toLocaleString().replace(/\s/g, ",")}</h1>
            <img className='Left__Picture' src={obj.photo}></img>
        </div>
    )
}

export default Left
