import React, { useEffect, useState } from 'react'

const Counter = ({ maxCount,maxReached, }) => {
    const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = 10 ;

    if (count < maxCount) {
      const intervalId = setInterval(() => {
        setCount(count => count + (maxCount/100));
      }, interval);

      return () => clearInterval(intervalId);
    }
    else{
      setCount(maxCount)
      setTimeout(() => {
        maxReached()

      }, 1000);
    }
  }, [count, maxCount]);

  return (
    <div>
<h1 className='Right__Networth'>
  ${count.toLocaleString().replace(/\s/g, ",")}
</h1>
    </div>
  );
}

export default Counter
