import React ,{useEffect,useState}from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { AiOutlineArrowUp } from 'react-icons/ai'
import '../App.css';

const Right = ({ obj, setClicked,score }) => {
    const [buttonsActive,setButtonsActive] = useState(true)
    useEffect(() => {
        setButtonsActive(true)
    }, [score]);
    
    
    const handleMoreClick = () => {
        setClicked('more');
        setButtonsActive(false)
    };

    const handleLessClick = () => {
        setClicked('less');
        setButtonsActive(false)
    };

    return (
        <div className='Right'>
            <h1 className='Right__Name'>{obj.name}</h1>
            <img className='Right__Picture' src={obj.photo}></img>
            <div className='Right__Buttons'>
               {buttonsActive && <button className='Right__Button' onClick={handleMoreClick}>More <AiOutlineArrowUp /></button>} 
               {buttonsActive && <button className='Right__Button' onClick={handleLessClick}>Less <AiOutlineArrowDown /></button>} 
            </div>
        </div>
    )
}

export default Right