import React from 'react'
const Start = ({onPageChange}) => {
  
    function handleStartGame() {
        onPageChange('game');
      }
    return (
    <div className='Start'>
      <h1 className='Start__text'>Who is richer</h1>
      <button className='Start__button' onClick={handleStartGame}>Start Game</button>
    </div>
  )
}

export default Start
