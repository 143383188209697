import Start from './commponents/Start';
import './App.css';
import { useState } from 'react';
import End from './commponents/End';
import Game from './commponents/Game';

function App() {
  const [page,setPage] = useState('start')
  const [highScore, sethighScore] = useState(0)
  const [point, setPoint] = useState(0);
  function handlePageChange(newPage) {
    setPage(newPage);
  }
  
  return (
    <div className="App">
        {page === 'start' ? <Start onPageChange={handlePageChange}/>:page === 'game' ? <Game onPageChange={handlePageChange} sethighScore={sethighScore} highScore={highScore} setPoint={setPoint}/>:<End onPageChange={handlePageChange} score={point}/>}
    </div>
  );
}

export default App;
