import React from 'react';
import { FaTwitterSquare } from 'react-icons/fa';

const ShareOnTwitter = ({ text, url, hashtags }) => {
  const encodedHashtags = encodeURIComponent(hashtags.replace(/,/g, ' '));
  const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}&hashtags=${encodedHashtags}`;

  const handleClick = () => {
    window.open(shareUrl, '_blank');
  };

  return (
    <button onClick={handleClick} className='twitter'>
      <FaTwitterSquare /> Share
    </button>
  );
};

export default ShareOnTwitter;