import React, { useState, useEffect } from 'react'
import ShareOnTwitter from './ShareOnTwitter';
import ShareOnFacebook from './ShareOnFacebook';

const End = ({ onPageChange, score }) => {
  const [text, setText] = useState('')
  useEffect(() => {
    if (score < 2) {
      setText("Looks like you're really good at losing. Maybe try playing with your eyes open next time?");
    }
    else if (score > 1 && score < 6) {
      setText("Well, you managed to score a few points. That's something, I guess. Keep trying and you might even get to double digits one day!")
    }
    else if (score > 5 && score < 11) {
      setText("Not terrible, but definitely not impressive. Don't quit your day job just yet.")
    }
    else if (score > 10 && score < 16) {
      setText("Good job! You're making progress and getting better with every game.")
    }
    else if (score > 15 && score < 21) {
      setText("Great work! You're really starting to get the hang of this game.")
    }
    else if (score > 21 && score < 26) {
      setText("Impressive performance! You're definitely a skilled player.")
    }
    else if (score > 25 && score < 30) {
      setText("Fantastic job! You're a true master at this game.")
    }
    else {
      setText("Wow, a perfect score! You're a true champion of this game.")
    }
  }, [score]);
  function handleStartGame() {
    onPageChange('game');
  }
  return (
    <div className='End'>
      <h1 className='End__YouScored'>You scored:</h1>
      <h1 className='End__score'>{score}</h1>
      <div className='shares'>
        <ShareOnTwitter 
          url="https://whoisrichergame.com/"
          text={`🚀 Check out this awesome game! I just scored ${score} points! 🎉🎮🔥`}
          hashtags="WhoIsRicherGame"
        />
        <ShareOnFacebook />
      </div>
      <h1 className='End__text'>{text}</h1>
      <button className='Start__button' onClick={handleStartGame}>Try Again</button>
    </div>
  )
}

export default End
